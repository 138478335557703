<template>
  <div class="notification-manager">
    Менеджер: {{ userManagerInfo?.name }}

    <div v-if="isAuthUser" class="--user-auth-profile">
      <img src="~/assets/img/svg/common/user.svg"/>
      ЛК авторизован
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';

const {
  userManagerInfo
} = useSitisUserManager();

const {
  currentUser
} = useSitisAuth();
const isAuthUser: ComputedRef<boolean> = computed(() => {
  return Boolean(currentUser?.value)
})
</script>

<style scoped lang="scss">
.notification-manager {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  pointer-events: none;

  background: #E8273E;
  box-shadow: 0px 4px 4px rgba(139, 41, 141, 0.12), 0px 6px 24px rgba(139, 41, 141, 0.16);
  border-radius: 9px;
  font-size: 20px;
  line-height: 20px;
  padding: 4px 15px;
  color: white;
  font-weight: 500;
  min-height: 40px;

  .--user-auth-profile {
    display: flex;
    align-items: center;
    margin-left: 12px;
    background: #45a900;
    border-radius: 5px;
    padding: 2px 10px 2px 2px;
    box-sizing: border-box;

    font-size: 18px;
    line-height: 100%;

    img {
      filter: invert(1);
      margin-right: 2px;
    }
  }
}
</style>
